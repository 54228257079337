<template>
    <footer class="content-area__footer section-gap-30 d-flex justify-content-between">
        <p>{{ $t("Version")}}: {{appVersion}}</p>
        <p class="text-right">{{ $t("Last Updated")}}: {{appLastUpdate}}</p>
    </footer>
</template>

<script>
import {ENV} from "@/config/app";

export default {
        computed: {
            appVersion() {
                return ENV.VUE_APP_VERSION;
            },
            appLastUpdate() {
                return ENV.VUE_APP_LAST_UPDATED;
            }
        }
    }
</script>
